export default [
  {
    path: '/admin',
    name: 'admin',
    children: [
      {
        path: 'staffs',
        name: 'staffs',
        component: () => import('../view/admin/staffs.vue'),
      },
      {
        path: 'configs',
        name: 'configs',
        component: () => import('../view/admin/config.vue'),
      },
      {
        path: 'paymethods',
        name: 'paymethods',
        component: () => import('../view/admin/paymentMethods.vue'),
      },
      {
        path: 'campaigns',
        name: 'campaigns',
        component: () => import('../view/tools/campaigns/index.vue'),
      },
    
    ],
  },

];
