/* eslint-disable no-unused-vars */
const postError = (error) => {
    console.log(error);
    
   switch (error.status) {
        case 400:
             return error.data.message;
        case 422:
             return 'Please fill in all required fields';
        case 401:
             return 'You are not authorized to access this page';
        case 403:
             return 'Access Denied';
        case 404:
             return 'Not Found';
        case 500:
             return 'We are sorry, but the server has encountered an error';
        default:
             return 'Unknown Error';
     }
};



export { postError };
