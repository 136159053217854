export default [
  {
    path: '/transactions',
    name: 'transactions',
    children: [
      {
        path: 'wallet-transactions',
        name: 'wallet-transactions',
        component: () => import('../view/transactions/walletWithdraws.vue'),
      },
      {
        path: 'shop-transactions',
        name: 'shop-transactions',
        component: () => import('../view/transactions/shopTransactions.vue'),
      },
      
    ],
  },

];
