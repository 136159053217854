import {DataService} from '../config/dataService/dataService';
import { message } from "ant-design-vue";
import {postError} from "./helper/errorHandler";

const API_URL = `${process.env.VUE_APP_API}/staff/`;

class ApiService {
  staffProfile() {
    return DataService
      .get(API_URL + "staff-profile")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
       message.error(postError(error.response));
        return Promise.reject(error);
      });
  }
 
}

export default new ApiService();
