export default [
  {
    path: '/shipping',
    name: 'shipping',
    children: [
      {
        path: 'branches',
        name: 'branches',
        component: () => import('../view/shipping/branches/branches.vue'),
      },
      {
        path: 'add-branch',
        name: 'addBranch',
        component: () => import('../view/shipping/branches/addBranch.vue'),
      },
      {
        path: "branches/:id",
        name: "single-branches",
        component: () =>
          import(
             "../view/shipping/branches/singleBranch.vue"
          ),
      },
      {
        path: 'shippig-partners',
        name: 'shippig-partners',
        component: () => import('../view/shipping/partners/shippingPartners.vue'),
      },
        {
        path: "shippig-partners/:id",
        name: "singleShippingPartner",
        component: () =>
          import(
             "../view/shipping/partners/singleShippingPartner.vue"
          ),
      },
      {
        path: 'park-shipments',
        name: 'park-shipments',
        component: () => import('../view/shipping/parks/parkShipments.vue'),
      },
        {
        path: "park-shipments/:id",
        name: "singleParkShipment",
        component: () =>
          import(
             "../view/shipping/parks/singleParkShipments.vue"
          ),
      },
      {
        path: 'riders',
        name: 'riders',
        component: () => import('../view/shipping/riders/riders.vue'),
      },
       {
        path: 'parks',
        name: 'shippig-parks',
        component: () => import('../view/shipping/parks/parks.vue'),
      },
    ],
  },

];
